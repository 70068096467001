import React from "react"
import { graphql } from "gatsby"

import "../styles/components/blog.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY")
      }
      html
    }
  }
`

const Blog = ({
  data: {
    markdownRemark: { frontmatter, html },
  },
}) => {
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <article className="blog-post">
        <div className="blog-post__title">
          <h1>{frontmatter.title}</h1>
        </div>
        <div className="blog-post__date">
          <h4>{frontmatter.date}</h4>
        </div>
        {/* {frontmatter.banner_img && (
          <img
            src={`/${frontmatter.banner_img}`}
            alt={frontmatter.title}
            className="blog-post__image"
          />
        )} */}
        <div
          className="blog-post__content"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      </article>
    </Layout>
  )
}

export default Blog
